import { useHttp } from '@/middleware/httpMiddleware';

async function getTasks (filter, skip = 0) {
  const data = await useHttp(
    `/integrations/tasks/${filter?.type}`,
    'GET',
    null,
    {
      skip,
      credentials: filter.credentials || '',
      status: filter.status || '',
      workflow: filter.workflows || '',
      profile_id: filter.profile_id || '',
      master_task: filter.master_task || '',
    },
    false
  );

  return data.payload;
}

async function getTaskInfo (spaceInfo, userInfo, type, taskId) {
  const data = await useHttp(
    '/profiles/emails',
    'GET',
    null,
    {}
  );

  return data.payload;
}

export { getTasks, getTaskInfo };
